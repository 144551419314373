import AboutMe from "../AboutMe";
import ContactMe from "../ContactMe";
import Depoimentos from "../Depoimentos";
import HeroSection from "../HeroSection";
import MyPortfolio from "../MyPortfolio";
import MySkills from "../MySkills";
import Footer from "../Footer";

export default function Home() {
    return(
        <>
            <HeroSection />
            <MySkills />
            <AboutMe  />
            <MyPortfolio  />
            <Depoimentos />
            <ContactMe/>
            <Footer />
        </>
    )
}