export default function AboutMe() {
    return <section id="AboutMe" className="about--section">
        <div className="about--section--img">
            <img src="./img/about-me.png" alt="About Me" />
        </div>
        <div className="hero--section--content--box about--section--box">
            <div className="hero--section--content"><p className="section--title">Sobre</p>
            <h1 className="skills--section--heading">Sobre Mim</h1>
            <p className="hero--section--description">
            Olá, meu nome é Éder, sou um apaixonado por tecnologia, Tenho experiência de mais de 3 anos em design e Web design, e também atuo há um tempo como professor de informática e professor de inglês.
Atualmente estou estudando programação com React.js, e logo next.js
            </p>
            </div>
        </div>
    </section>
}