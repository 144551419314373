export default function HeroSection() {
    return <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
            <div className="hero--section--content">
                <p className="section--title">
                    Olá meu nome é Éder
                </p>
                <h1 className="hero--section--title">
                    <span className="hero--section--title--color">Front End</span>{" "}               
                    Developer
                </h1>
                <p className="hero--section--description">Olá, meu nome é Éder, Atualmente estou trabalhando com a stack React.js, mas logo mais também estarei trabalhando com Next.js.
                <br />
                Mande uma mensagem no email eder.a.lima@gmail.com, ou clique no botão abaixo:</p>
            </div>
            <a href="https://api.whatsapp.com/send?phone=5511953224486" target="_blank"><button className="btn btn-primary">Me mande uma mensagem!</button></a>
        </div>
        <div className="hero--section--img">
            <img src="./img/hero_img.png" alt="Hero Section" />
        </div>
    </section>;
}