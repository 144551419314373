export default function ContactMe() {
    return(
        <section id="Contact" className="contact--section">
            <div>
                <p className="sub--title">
                    Entre em Contato
                </p>
                <h2>Contato</h2>
                <p className="text-lg">
                    Escolha um tópico e mande uma mensagem.
                </p>
            </div>
            <form className="contact--form--container">
                <div className="container">
                    <label htmlFor="nome" className="contact--label">
                        <span className="text-md">
                            Nome
                        </span>
                        <input type="text" className="contact--input text-md" name="nome" id="nome" required/>
                    </label>
                    <label htmlFor="sobrenome" className="contact--label">
                        <span className="text-md">
                            Sobrenome
                        </span>
                        <input type="text" className="contact--input text-md" name="sobrenome" id="sobrenome" required/>
                    </label>
                    <label htmlFor="email" className="contact--label">
                        <span className="text-md">
                            Email
                        </span>
                        <input type="email" className="contact--input text-md" name="email" id="email" required/>
                    </label>
                    <label htmlFor="phone-number" className="contact--label">
                        <span className="text-md">
                            WhatsApp
                        </span>
                        <input type="number" className="contact--input text-md" name="phone-number" id="phone-number" required/>
                    </label>
                </div>
                <label htmlFor="topicos" className="contact--label">
                        <span className="text-md">
                            Escolha um Tópico
                        </span>
                        <select id="topico" className="contact--input text-md">
                            <option>Selecione Um...</option>
                            <option>Tenho uma proposta de emprego</option>
                            <option>gostaria de falar sobre um projeto</option>
                            <option>Outros</option>
                        </select>
                    </label>
                    <label htmlFor="message" className="contact--label">
                        <span className="text-md">
                            Mensagem
                        </span>
                        <textarea className="contact--input text-md" id="message" rows="8" placeholder="Digite a sua mensagem..." />
                    </label>
                    <div>
                        <button className="btn btn-primary contact--form--btn">
                            Enviar
                        </button>
                    </div>
            </form>
        </section>
    )
}