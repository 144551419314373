import data from "../../data/index.json"
import {BsGithub} from "react-icons/bs"

export default function MyPortfolio() {
    return (
        <section className="portfolio--section" id="MyPortfolio">
            <div className="portfolio--container--box">
                <div className="portfolio--container">
                    <p className="sub--title">
                        Projetos
                    </p>
                    <h2 className="section--heading">
                        Meu Portfólio
                    </h2>
                </div>
                <div>
                    <span></span>
                    <a href="https://github.com/ederlima11" target="_blank"><button className="btn btn--github">
                         <BsGithub/>  Visite Meu GitHub
                    </button></a>
                </div>
            </div>
            <div className="portfolio--section--container">
                {data?.portfolio?.map((item, index) => (
                    <div key={index} className="portfolio--section--card">
                        <div className="portfolio--section--img">
                        <img src={item.src} alt="PlaceHolder" />
                        </div>
                        <div className="portfolio--section--card--content">
                            <div>
                                <h3 className="portfolio--section--title">{item.title}</h3>
                                <p className="text-md">{item.description}</p>
                            </div>
                            <p className="text-sm portfolio--link">
                            <a href={item.endereco} target="_blank"> {item.link} </a>
                            </p>
                           
                        </div>
                    </div>
                ))}
            </div>
            
        </section>
        );
}